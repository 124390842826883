import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import hook_loader from "../../public/static/images/products/hook_loader.jpg"

function HookLoader() {

  const prodDetail = {
    "name": "Hook-Loader",
    "detail": {
      "about" : ["One System Can Easily Handle Lifting, Shifting and Dumping 10/12 Containers During Working Hours.", "It Facilitates Safe and Hygienic Collection, Storage and Large Volume of Garbage Transportation With Fast Operation."],
      "advantages": ["Containers of 14 to 20 Cubic Meters are Placed at Different Fixed Transfer Stations. Garbage is Collected Throughout The City With The Help of Light Vehicles Such as Small Capacity Tippers, Dumper Placers Etc. This Garbage Is Transferred to These Bigger Containers. Hook-loader Lifts These Containers and Carriers to Garbage Dumping Groung For Emptying."]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Booms",
        "Arrangement"
      ],
      "column2": [
        "IEHL",
        "12-25 Ton or Customized",
        "Heavy Duty Booms of CRC/Eq. Sheets",
        "Hydraulic Cylinder of High Load Capacity, Hydraulic Pump & Others Efficient Controlling Systems to Easy Lifting of Big Size Containers From One Place to Other."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={hook_loader} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default HookLoader
